import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import EmployeeItem from "../components/employee"

import OuluPicture from "../images/oulu.jpg"
import VantaaPicture from "../images/vantaa.jpg"

import * as qs from "querystring"
import Picture from "../images/blog-post.jpg"

export default class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    this.domRef = React.createRef()
    this.state = {
      feedbackTitle: null,
      feedbackDesc: null,
      feedbackMobile: null,
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    const formData = {}
    Object.keys(this.refs).map(key => (formData[key] = this.refs[key].value))

    fetch(this.props.location.pathname, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      method: "POST",
      body: qs.stringify(formData),
    })
      .then(response => {
        this.setState({
          feedbackTitle: "Kiitos yhteydenotostasi!",
          feedbackDesc: "Otamme sinuun yhteyttä mahdollisimman pian",
          feedbackMobile: "",
        })
        this.domRef.current.reset()
      })
      .catch(err =>
        this.setState({
          feedbackTitle: "Jokin meni pieleen!",
          feedbackDesc:
            "Voit vaihtoehtoisesti ottaa yhteyttä sähköpostiste osoitteeseen info (at) ebrand.fi",
        })
      )
  }

  render() {
    return (
      <Layout>
        <SEO
          title={"Yhteystiedot"}
          description={
            "Älä epäröi ottaa meihin yhteyttä. Sähköposti: info (at) ebrand.fi. Teknobulevardi 3-5, 01530 Vantaa."
          }
          image={Picture}
        />
        <Header siteTitle={"Yhteystiedot"} />
        <section className="slice slice-lg">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="card bg-dark opacity-container text-white overflow-hidden border-0 shadow-lg">
                  <a
                    href={
                      "https://www.google.com/maps/place/Tahtipuikko+2,+90140+Oulu/"
                    }
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <div
                      className="card-img-bg"
                      style={{ backgroundImage: "url(" + OuluPicture + ")" }}
                    />
                    <span className="mask bg-gradient-primary opacity-9 opacity-8--hover" />
                    <div className="card-body px-5 py-5">
                      <div style={{ minHeight: "250px" }}>
                        <h2 className="h2 text-white font-weight-bold mb-4">
                          Oulun toimisto
                        </h2>
                        <h5 className="text-white mt-4 mb-2">
                          Tahtipuikko 2
                        </h5>
                        <h5 className="text-white">90220 Oulu</h5>
                      </div>
                      <span className="text-white text-uppercase font-weight-bold">
                        Katso kartalla
                      </span>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card bg-dark opacity-container text-white overflow-hidden border-0 shadow-lg">
                  <a
                    href={
                      "https://www.google.fi/maps/place/Teknobulevardi+3,+01530+Vantaa/"
                    }
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <div
                      className="card-img-bg"
                      style={{ backgroundImage: "url(" + VantaaPicture + ")" }}
                    />
                    <span className="mask bg-gradient-dark opacity-9 opacity-8--hover" />
                    <div className="card-body px-5 py-5">
                      <div style={{ minHeight: "250px" }}>
                        <h2 className="h2 text-white font-weight-bold mb-4">
                          Vantaan toimisto
                        </h2>
                        <h5 className="text-white mt-4 mb-2">
                          Teknobulevardi 3-5
                        </h5>
                        <h5 className="text-white">01530 Vantaa</h5>
                      </div>
                      <span className="text-white text-uppercase font-weight-bold">
                        Katso kartalla
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="container slice slice-lg">
            <div className="row row-grid align-items-center">
              <div className={"col-lg-6"}>
                <h3>
                  {this.state.feedbackTitle
                    ? this.state.feedbackTitle
                    : "Ota yhteys puhelimitse:"}
                </h3>
                <h5 className={"font-weight-normal"}>
                  {this.state.feedbackMobile !== null ? "" : "Esa Piiroinen"}{" "}
                  <a href={"tel:+358 44 7779171"} style={{ color: "#306eff" }}>
                    {this.state.feedbackMobile !== null
                      ? this.state.feedbackMobile
                      : "+358 44 7779171"}
                  </a>
                </h5>
                <p className="lead">
                  {this.state.feedbackDesc
                    ? this.state.feedbackDesc
                    : "Vaihtoehtoisesti yhteys alla olevalla lomakkeella"}
                </p>
                <form
                  className={"mt-5"}
                  method={"POST"}
                  data-netlify="true"
                  name="Contact Form"
                  onSubmit={event => this.handleSubmit(event)}
                  ref={this.domRef}
                >
                  <input
                    type="hidden"
                    ref="form-name"
                    name="form-name"
                    value="Contact Form"
                  />
                  <div className="form-group">
                    <label className="sr-only">Nimi (pakollinen)</label>
                    <input
                      className="form-control form-control-lg"
                      placeholder="Nimi"
                      type="text"
                      name="fullname"
                      required
                      ref="fullname"
                    />
                  </div>
                  <div className="form-group">
                    <label className="sr-only">Sähköposti (pakollinen)</label>
                    <input
                      className="form-control form-control-lg"
                      placeholder="Sähköposti"
                      name="email"
                      type="email"
                      required
                      ref="email"
                    />
                  </div>
                  <div className="form-group">
                    <label className="sr-only">Aihe</label>
                    <input
                      className="form-control form-control-lg"
                      placeholder="Aihe"
                      type="text"
                      name="title"
                      ref="title"
                    />
                  </div>
                  <div className="form-group">
                    <label className="sr-only">Viesti (pakollinen)</label>
                    <textarea
                      className="form-control form-control-lg textarea-autosize"
                      rows="5"
                      placeholder="Viesti"
                      name="message"
                      required
                      ref="message"
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary rounded-pill mt-4"
                  >
                    Lähetä
                  </button>
                </form>
              </div>
              <div className="col-lg-5 ml-lg-auto">
                <h3>Hallinto ja postiosoite</h3>
                <p className={"lead"}>Teknobulevardi 3-5, 01530 Vantaa</p>
                <p className="lead my-4">
                  Sähköposti: info (a) ebrand.fi
                  <br />
                  Puhelin: <a href="tel:+358 44 7779171">+358 44 7779171</a>
                </p>
              </div>
            </div>
          </div>
          <div className="container slice slice-lg pb-4">
            <div className="mb-5 text-center">
              <h2 className="mt-4">Työntekijät</h2>
              <div className="fluid-paragraph mt-3">
                <p className="lead lh-180">
                  Kokenut tiimimme toteuttaa vaativimmatkin ohjelmistokehitykset
                  sujuvasti alkukartoituksesta käyttöönottoon.
                </p>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <EmployeeItem
                name={"Esa Piiroinen"}
                desc={"Toimitusjohtaja"}
                email={"etunimi.sukunimi (a) ebrand.fi"}
                phone={"+358 44 7779 171"}
              />
              <EmployeeItem
                name={"Jukka Weissenfelt"}
                desc={"Palvelujohtaja"}
                email={"etunimi.sukunimi (a) ebrand.fi"}
                phone={"+358 44 5103 700"}
              />
              <EmployeeItem
                name={"Anton Yrjönen"}
                desc={"Teknologiajohtaja"}
                email={"etunimi.sukunimi (a) ebrand.fi"}
                phone={"+358 40 5774 507"}
              />
              <EmployeeItem
                name={"Petri Paakkola"}
                desc={"Ohjelmistoarkkitehti"}
                email={"etunimi.sukunimi (a) ebrand.fi"}
              />
              <EmployeeItem
                name={"Joni Paloniemi"}
                desc={"Backend-kehittäjä"}
                email={"etunimi.sukunimi (a) ebrand.fi"}
              />
              <EmployeeItem
                name={"Ville Heikkinen"}
                desc={"Full stack -kehittäjä"}
                email={"etunimi.sukunimi (a) ebrand.fi"}
              />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
