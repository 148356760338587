import React from "react"
import PropTypes from "prop-types"
import ScrollAnim from "react-animate-on-scroll"

const EmployeeItem = ({ name, desc, email, phone }) => (
  <div className="col-11 col-sm-6 col-lg-4 mb-4">
    <ScrollAnim
      animateIn="fadeIn"
      duration="0.5"
      animateOnce="true"
      offset="120"
    >
      <div className="card hover-shadow-lg hover-translate-y-n10">
        <div className="card-body py-5 text-center">
          <h4 className="d-block h4 lh-150 mb-0">{name}</h4>
          <p className={"font-weight-bold"}>{desc}</p>
          <p className={"m-0"}>{email}</p>
          <p className={"m-0"}>
            <a href={"tel:" + phone}>{phone}</a>
          </p>
        </div>
      </div>
    </ScrollAnim>
  </div>
)

EmployeeItem.propTypes = {
  name: PropTypes.node.isRequired,
  desc: PropTypes.node.isRequired,
  email: PropTypes.node.isRequired,
  phone: PropTypes.string,
}

EmployeeItem.defaultProps = {
  name: "",
  desc: "",
  email: "",
  phone: "",
}

export default EmployeeItem
